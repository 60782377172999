.components {
  // padding-top: 25px
  overflow: hidden;
  @include md {
    border-top: 1px solid  $c-border;
    padding-top: 25px; }
  &__img {
    text-align: right;
    padding-top: 25px;
    padding-bottom: 45px;
    position: relative;
    overflow: hidden;
    img {
      position: relative;
      z-index: 1;
      @include md {
        height: auto; } }
    @include before {
      background-color: #fff;
      z-index: 0;
      border-top: 1px solid  $c-border;
      border-bottom: 1px solid  $c-border;
      position: absolute;
      width: 150vw;
      height: 100%;
      top: 0;
      left: 35%;
      transform: translate(-50%, 0);
      @include md {
        display: none; } } }
  &__img-in {
    // overflow: auto
    // overflow: hidden
    display: inline-block;
    font-size: 0; }
  &__img-controls {
    position: absolute;
    z-index: 1;
    bottom: 20px;
    left: 0; }
  &__img-button {
    background-color: $c-gray;
    color: #fff;
    width: 30px;
    height: 30px;
    @include inline-flex(center, center);
    font-size: 1.5em;
    vertical-align: middle; }
  &__img-reset {
    font-size: 1em;
    width: auto;
    padding-left: 10px;
    padding-right: 10px; }
  &__list {
    position: relative;
    z-index: 1;
    padding-top: 25px;
    @include md {
      display: none;
      padding-bottom: 25px;
      padding-top: 0; } }
  &__table {
    padding-top: 35px;
    position: relative;
    padding-bottom: 30px;
    table, .table {
      position: relative;
      z-index: 1; } }
  &__table-bg {
    position: absolute;
    width: 150vw;
    height: 100%;
    background-color: #f5f5f5;
    z-index: 0;
    top: 0;
    left: 35%;
    transform: translate(-50%, 0);
    @include md {
      border-top: 1px solid $c-border; } }
  &__show-btn {
    display: none;
    margin-bottom: 25px;
    @include md {
      display: block; } } }

.components-list {
  box-shadow: 0px 0px 6px 0px rgba(0, 1, 1, 0.19);
  background-color: #fff;
  margin-bottom: 30px;
  &__title {
    font-size: 14px;
    color: #383838;
    text-transform: uppercase;
    @include bold;
    display: block;
    border-top: 1px solid  $c-border;
    border-bottom: 1px solid  $c-border;
    position: relative;
    padding-left: 15px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    @include tr(background-color .3s, color .3s);
    @include before {
      @include icon-arrow(10, 10, 1, $c-border, -135);
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%) rotate(-135deg);
      @include tr(transform .3s); }
    &.is-active {
      background-color: $c-accent;
      color: #fff;
      @include before {
        transform: translate(0, -50%) rotate(-45deg); } }
    // +hover
 }    //   @extend .components-list__title.is-active
  &__list {
    display: none; }
  &__list-item {
    // padding-top: 10px
    // padding-bottom: 10px
 }    // bgc
  &__subttl {
    display: block;
    background-color: #f5f5f5;
    width: 100%;
    padding: 10px 12px;
    text-decoration: underline;
    position: relative;
    font-size: 14px;
    @include bold;
    @include tr(color .3s);
    @include hover {
      color: $c-accent; }
    @include before {
      @include icon-arrow(10, 10, 1, currentColor, -135);
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%) rotate(-135deg);
      @include tr(transform .3s); }
    &.is-active {
      @include before {
        transform: translate(0, -50%) rotate(-45deg); } } }
  &__list-link {
    @include light;
    font-size: 14px;
    color: #383838;
    text-decoration: underline;
    @include tr(color .3s);
    padding-left: 15px;
    padding-right: 20px;
    @include hover {
      color: $c-accent;
      text-decoration: none; } }
  &__sublist {
    display: none; } }

.components-sublist {
  font-size: 14px;
  &__item {
    padding: 10px 12px; }
  &__link {
    text-decoration: underline;
    @include tr(color .3s);
    @include hover {
      color: $c-accent; } } }
