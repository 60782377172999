.form {
  $this: &;
  &__conf {
    color: $c-gray;
    text-align: center;
    max-width: 365px;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
    padding-top: 5px;
    a {
      text-decoration: underline;
      color: #8388c7; } }
  &--hor {
    // +flex(space-between, center)
    form {
      width: 100%;
      @include flex(space-between, center, row, wrap);
      // margin-left: -15px
 }      // margin-right: -15px
    #{$this}__field {
      position: relative;
      width: 20%;
      padding-left: 15px;
      padding-right: 15px;
      @include lg {
        width: 50%;
        margin-bottom: 15px; }
      @include xs {
        width: 100%; } }
    #{$this}__field-lg-100 {
      @include lg {
        width: 100%; } }
    #{$this}__field-4 {
      padding-left: 15px;
      padding-right: 15px;
      width: 80%; } }

  &--cabinet {
    background-color: #fff;
    #{$this}__top {
      border-bottom: 1px solid  $c-border;
      padding: 18px 34px 12px;
      @include flex(space-between, center);
      @include sm {
        padding: 10px 20px 12px; }
      @include xs {
        padding: 10px; } }
    #{$this}__block {
      padding: 30px 34px 15px;
      font-size: 0;
      @include sm {
        padding: 20px 20px 15px; }
      @include xs {
        padding: 10px; } }
    #{$this}__field {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 15px;
      @include lg {
        input {
          width: 70%; }
        label {
          width: 30%; } }
      @include md {
        input {
          width: 75%; }
        label {
          width: 25%; } }
      @include sm {
        width: 100%;
        margin-bottom: 10px;
        input, label {
          width: 100%; } }
      &:nth-child(even) {
        padding-left: 40px;
        @include md {
          padding-left: 20px; }
        @include sm {
          padding-left: 0; } }
      &:nth-child(odd) {
        padding-right: 40px;
        @include md {
          padding-right: 20px; }
        @include sm {
          padding-right: 0; } }
      input {
        width: 365px; } } }

  &--block {
    --form-block-gap: 15px;
    @include md {
      --form-block-gap: 10px; }
    @include sm {
      --form-block-gap: 0; }
    #{$this}__row {
      font-size: 0;
      margin-left: calc(0px - var(--form-block-gap));
      margin-right: calc(0px - var(--form-block-gap)); }
    #{$this}__field {
      margin-bottom: 15px;
      width: 50%;
      display: inline-block;
      vertical-align: top;
      padding-left: var(--form-block-gap);
      padding-right: var(--form-block-gap);
      @include sm {
        width: 100%; } }
    #{$this}__field-mb-lg {
      margin-bottom: 35px; }

    #{$this}__field-full {
      width: 100%;
      .select {
        max-width: calc(50% - 15px);
        @include sm {
          max-width: none; } } } } }

.form-block {
  --form-block-padding: 30px;
  background-color: #fff;
  padding: var(--form-block-padding) var(--form-block-padding) calc(var(--form-block-padding) / 2);
  margin-bottom: 30px;
  @include md {
    --form-block-padding: 15px; }
  @include md {
    margin-bottom: 15px; }
  &__title {
    margin-bottom: 15px; }

  &--sm {
    --form-block-padding: 50px;
    padding-top: calc(var(--form-block-padding) / 1.5); } }



.input {
  input {
    border: 1px solid  $c-border;
    @include light;
    width: 100%;
    padding: 5px;
    font-size: 15px;
    color: inherit;
    height: 41px;
    @include placeholder {
      @include light;
      color: inherit; } }
  label {
    color: inherit;
    @include light;
    font-size: 14px; }


  &--lg {
    input {
      height: 50px;
      padding-left: 18px;
      padding-right: 18px;
      @include sm {
        height: 44px; } } }
  &--sm {
    color: #a5a5a5;
    input {
      font-size: 14px;
      padding-left: 15px;
      padding-right: 15px;
      color: inherit; }
    label {
      color: $c-text;
      margin-bottom: 5px;
      display: block;
      sup {
        color: #fbcf3a;
        font-size: inherit;
        display: inline-block;
        margin-left: .2em; } } }
  &--label {
    @include flex(space-between, center);
    @include sm {
      display: block;
      label {
        display: block;
        margin-bottom: 5px; } } }
  &--password {
    position: relative;
    button {
      color: #4171b9;
      background-color: transparent;
      text-transform: uppercase;
      @include roman;
      font-size: 12px;
      position: absolute;
      bottom: 12px;
      right: 15px;
      &.is-active {
        span {
          &:nth-child(1) {
            display: none; }
          &:nth-child(2) {
            display: inline; } } }
      span {
        &:nth-child(1) {}
        &:nth-child(2) {
          display: none; } } }
    input {
      padding-right: 80px; } } }

.textarea {
  font-size: 15px;
  label {
    display: block;
    margin-bottom: 5px; }
  textarea {
    font-size: 14px;
    color: #a5a5a5;
    border: 1px solid  $c-border;
    @include light;
    resize: none;
    width: 100%;
    padding: 5px 10px;
    min-height: 120px;
    @include placeholder {
      color: inherit;
      font-family: inherit; } } }
