.parts-links {
  .row {
    @include xs {
      margin-left: -5px;
      margin-right: -5px; } }
  [class*="col-"] {
    @include xs {
      padding-left: 5px;
      padding-right: 5px; } } }

.parts-link {
  @include flex(flex-start, center);
  width: 100%;
  border: 1px solid $c-border;
  padding: 10px 12px;
  padding-left: 20px;
  padding-right: 30px;
  margin-bottom: 18px;
  position: relative;
  @include tr(color .3s, border-color .3s);
  @include hover {
    border-color: $c-accent;
    color: $c-accent; }
  @include before {
    display: inline-block;
    pointer-events: none;
    top: 50%;
    right: 14px;
    @include icon-arrow(8, 8, 1, currentColor, -135);
    transform: translate(0, -50%) rotate(-135deg);
    position: absolute; }
  @include md {
    padding-left: 10px;
    margin-bottom: 10px; }
  @include xs {
    padding-top: 14px;
    padding-bottom: 14px;
    margin-bottom: 5px; }
  &__img {
    margin-right: 35px;
    width: 35px;
    display: block;
    font-size: 0;
    @include md {
      margin-right: 15px; }
    @include xs {
      width: 20px;
      height: 20px;
      margin-right: 5px; } }
  &__title {
    max-width: calc(100% - 65px);
    @include text-overflow;
    display: inline-block;
    @include md {
      max-width: calc(100% - 35px); }
    @include xs {
      max-width: calc(100% - 25px); } } }



.parts {
  .row {
    @include sm {
      margin-left: -10px;
      margin-right: -10px; }
    @include xs {
      margin-left: -5px;
      margin-right: -5px; } }
  [class*="col-"] {
    @include sm {
      padding-left: 10px;
      padding-right: 10px; }
    @include xs {
      padding-left: 5px;
      padding-right: 5px; } } }

.part {
  $this: &;
  @include cover;
  // background-size: 150%
  background-size: contain;
  background-color: #fff;
  background-position: 100% 20%;
  position: relative;
  width: 100%;
  @include flex(flex-end, normal, column);
  border: 1px solid  $c-border;
  padding-top: 100%;
  height: calc(100% - 30px);
  margin-bottom: 30px;
  @include tr(box-shadow .3s);
  @include hover {
    box-shadow: 0px 0px 12.92px 4.08px rgba(0, 0, 1, 0.3);
    #{$this}__btn {
      overflow: visible;
      max-height: 45px;
      padding-top: 15px;
      padding-bottom: 15px; }
    #{$this}__title {
      transform: translate(0, -30px);
      @include sm {
        transform: translate(0, 0); } } }
  @include before {
    @include coverdiv;
    z-index: 0;
    background: rgba(255,255,255,0);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 34%, rgba(255,255,255,1) 73%, rgba(255,255,255,1) 100%); }
  &__title {
    padding-left: 18px;
    padding-right: 18px;
    position: absolute;
    z-index: 1;
    bottom: 30px;
    left: 0;
    @include tr(transform .3s);
    @include sm {
      font-size: 12px;
      bottom: 20px;
      .h5, h5 {
        font-size: 12px; } }
    @include xs {
      bottom: 15px;
      font-size: 11px;
      .h5, h5 {
        font-size: 11px; } } }
  &__btn {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    @include tr(max-height .3s, padding .3s);
    @include sm {
      display: none; } }

  &--lg {
    padding-top: 0;
    @include cover;
    @include xs {
      padding-top: 100%; }
    #{$this}__title {
      color: #fff; } }
  &--model {
    background-size: cover;
    @include before {
      display: none; }
    @include hover {
      #{$this}__title {
        transform: translate(0, -45px); } }
    #{$this}__title {
      @extend .h5;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #fff;
      width: 100%;
      margin-bottom: 0;
      bottom: 0;
      @include tr(transform .3s); } } }

.parts-links-blocks {
  margin-left: -15px;
  margin-right: -15px;
  @include sm {
    margin-left: -7px;
    margin-right: -7px; }
  &__block {
    width: 33.33333%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    @include md {
      width: 50%; }
    @include sm {
      padding-left: 7px;
      padding-right: 7px;
      margin-bottom: 14px; }
    @include xs {
      width: 100%; } } }

.parts-links-block {
  $this: &;
  border-bottom: 0;
  &__title {
    @include flex(flex-start, center);
    @include bold;
    text-transform: uppercase;
    padding: 8px 20px;
    background-color: #f6f6f6;
    border: 1px solid  $c-border;
    margin-bottom: 10px;
    @include md {
      padding: 8px 15px; } }
  &__logo {
    margin-right: 8px;
    width: 30px;
    height: 30px;
    position: relative;
    img {
      // width: 100%
      max-height: 100%;
      @include vcenter; } }
  &__model {
    @include flex;
    padding: 15px;
    border: 1px solid  $c-border;
    margin-bottom: 10px;
    position: relative;
    @include tr(background-color .3s, transform .3s);
    @include hover {
      // background-color: $c-black
      #{$this}__link {
        text-decoration: underline;
        color: $c-accent; }
      #{$this}__img {
        transform: translate(10px, 0); }
      #{$this}__model-bg {
        visibility: visible;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.02); } }
    @include md {
      padding: 10px 15px; } }
  &__model-bg {
    @include vcenter;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: $c-black;
    @include tr(oacity .3s, visibiltiy .3s, transform .3s);
    opacity: 0;
    visibility: hidden; }
  &__img {
    // +flex(center, normal, column)
    flex: 0 0 154px;
    height: 60px;
    margin-right: 45px;
    font-size: 0;
    position: relative;
    z-index: 1;
    @include tr(transform .3s);
    @include lg {
      flex: 0 0 100px;
      margin-right: 20px; }
    @include sm {
      flex: 0 0 70px;
      margin-right: 10px;
      height: 30px; }
    img {
      max-height: 100%;
      @include vcenter; } }
  &__link {
    @include tr(color .3s); }
  &__link-wrap {
    @include flex(center, normal, column);
    position: relative;
    z-index: 1; } }
