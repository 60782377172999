.cart {
  background-color: #fff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.26);
  &__total {
    text-align: center;
    background-color: #f5f5f5;
    padding-top: 35px;
    padding-bottom: 35px;
    @include flex(center, center);
    @include md {
      padding: 20px; } }
  &__total-title {
    margin-top: .5em;
    margin-right: 10px; }
  &__btns {
    padding: 30px;
    @include flex(space-between, center);
    @include md {
      padding: 15px; }
    @include xs {
      display: block;
      text-align: center; } }
  &__btn {
    @include xs {
      .btn {
        width: 100%; }
      @include notlast {
        margin-bottom: 10px; } } } }

.cart-empty-block {
  text-align: center;
  margin-bottom: 95px;
  @include lg {
    margin-bottom: 70px; }
  @include md {
    margin-bottom: 55px; }
  @include sm {
    margin-bottom: 40px; }
  &__icon {
    margin-bottom: 25px;
    .icon {
      fill: $c-gray;
      font-size: 115px; } }
  &__title {
    margin-bottom: 5px; }
  &__text {
    margin-bottom: 15px;
    text-align: center;
    a {
      text-decoration: underline;
      color: $c-link; } } }
