.section {
  padding-top: 30px;
  padding-bottom: 40px;
  background-color: #fff;
  @include sm {
    padding-bottom: 30px; }
  &__title {
    margin-bottom: 34px;
    @include sm {
      margin-bottom: 20px; } }
  &__title-mb-sm {
    margin-bottom: 20px; }
  &__block {
    padding-top: 30px;
    padding-bottom: 30px; }
  &__gray-block {
      background-color: #fbfbfb; }
  &__block-mb {
    margin-bottom: 35px;
    @include md {
      margin-bottom: 25px; } }

  &--color {
    background-color: #f5f5f5; }
  &--no-pad {
    padding: 0;
    @include sm {
      padding: 0; } }
  &--no-pad-bottom {
    padding-bottom: 0;
    @include sm {
      padding-bottom: 0; } }
  &--no-pad-top {
    padding-top: 0;
    @include sm {
      padding-top: 0; } }
  &--pad-lg {
    padding-top: 95px;
    padding-bottom: 95px;
    @include md {
      padding-top: 60px;
      padding-bottom: 60px; }
    @include sm {
      padding-top: 30px;
      padding-bottom: 30px; } }
  &--border {
    border-top: 1px solid  $c-border;
    border-bottom: 1px solid  $c-border; }
  &--up {
    position: relative;
    z-index: 2;
    .container {
      // margin-top: -125px
      position: relative;
      top: -90px;
      margin-bottom: -90px;
 } }      // z-index: 2
  &--simple {
    @include xs {
      padding-top: 60px; } } }

.section-text {
  max-height: 50vh;
  position: relative;
  padding-right: 40px;
  padding-top: 30px;
  background-color: #fff;
  @include sm {
    padding-top: 15px; }
  @include xs {
    padding-right: 25px; }
  .h2, h2, .h3, h3 {
    margin-bottom: 5px; }
  a {
    color: $c-accent;
    text-decoration: underline; }
  p {
    margin-bottom: 30px;
    @include sm {
      margin-bottom: 20px; }
    @include xs {
      margin-bottom: 15px; } }
  ul, ol {
    // padding-left: 20px
    margin-bottom: 30px;
    @include sm {
      margin-bottom: 20px; }
    //   padding-left: 10px
    @include xs {
      margin-bottom: 15px; } }
  ul {
    li {
      position: relative;
      padding-left: 15px;
      &::before {
        content: "\2022";
        font-size: 20px;
        display: inline-block;
        color: $c-accent;
        position: absolute;
        left: 0;
        top: 0; } } }
  ol {
    padding-left: 20px;
    list-style-type: decimal;
    @include sm {
      padding-left: 25px; } }
  li {
    // margin-bottom: 15px
    // +xs
 }    //   margin-bottom: 10px

  &--shadow {
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.11);
    padding-left: 25px;
    position: relative;
    border-bottom: 25px solid  #fff;
    @include xs {
      padding-left: 15px; }
    // .ps
    //   &__rail-y
 } }    //     height: calc(100% + 25px) !important


