.accessoires {
  font-size: 0;
  margin-left: -15px;
  margin-right: -15px;
  @include sm {
    // margin-left: -10px
 }    // margin-right: -10px
  @include xs {
    margin-left: 0;
    margin-right: 0; } }

.accessoire {
  display: inline-block;
  vertical-align: top;
  width: calc(33.33333% - 30px);
  margin-right: 15px;
  margin-left: 15px;
  border: 1px solid  $c-border;
  margin-bottom: 35px;
  @include md {
    margin-bottom: 20px; }
  @include sm {
    width: calc(50% - 30px);
    // margin-right: 10px
 }    // margin-left: 10px
  @include xs {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
  &:nth-child(4) {
    margin-top: -45px;
    @include md {
      margin-top: -37px; }
    @include sm {
      margin-top: 0; } }
  &:nth-child(6) {
    margin-top: -140px;
    @include md {
      margin-top: -114px; }
    @include sm {
      margin-top: 0; } }

  &__img {
    position: relative;
    padding-top: 75%;
    @include cover; }
  &__title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba($c-black, .5);
    padding: 14px 20px;
    @include md {
      font-size: 14px;
      padding: 10px;
      .h5, h5 {
        font-size: 14px; } } }
  &__links {
    padding: 20px;
    @include md {
      padding: 10px; } }
  &__link {} }

