.nav {
  a {
    text-transform: uppercase;
    color: $c-text-100;
    font-size: 16px;
    @include light;
    @include tr(color .3s);
    @include hover {
      color: #acacac; }
    @include xs {
      font-size: 15px; } }
  li {
    display: inline-block;
    @include notlast {
      margin-right: 40px; }
    @include lg {
      display: block;
      margin-bottom: 15px;
      margin-right: 0;
      @include notlast {
        margin-right: 0; } }
    @include xs {
      margin-bottom: 10px; } }
  &--footer {
    li {
      display: block;
      @include notlast {
        margin-bottom: 20px;
        @include md {
          margin-bottom: 10px; } } }
    a {
      color: #727171;
      font-size: 15px;
      text-transform: none; } }
  &--aside {
    li {
      display: block;
      margin: 0;
      @include notlast {
        margin: 0;
        border-bottom: 1px solid  $c-border; } }
    a {
      display: block;
      color: #383838;
      font-size: 14px;
      @include bold;
      padding: 10px;
      background-color: #fff;
      @include tr(background-color .3s);
      @include hover {
        color: #fff;
        background-color: $c-accent; }
      &.is-active {
        color: #fff;
        background-color: $c-accent; } } }

  &--cabinet {
    @include sm {
      font-size: 0;
      padding-top: 15px; }
    li {
      font-size: 14px;
      @include lg {
        display: inline-block;
        margin-bottom: 0;
        @include notlast {
          margin-right: 30px; } }
      @include md {
        @include notlast {
          margin-right: 10px; } }
      @include sm {
        &:first-child {
          display: block;
          @include before {
            display: none; } } }
      @include xs {
        width: 50%;
        padding-right: 10px;
        @include notlast {
          margin-right: 0; } }
      @include xss {
        width: 100%; }

      &:first-child {
        @include medium;
        color: $c-text-100;
        position: relative;
        padding-right: 35px;
        @include before {
          width: 1px;
          height: 25px;
          position: absolute;
          background-color: $c-border;
          top: 50%;
          transform: translate(0, -50%);
          right: 0; } } }
    a {
      display: block;
      text-transform: none;
      color: #a5a5a5;
      font-size: 14px;
      @include light;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 2px solid transparent;
      @include tr(border-color .3s);
      @include sm {
        padding-top: 10px;
        padding-bottom: 10px; }
      @include xs {
        font-size: 14px; }
      &.is-active {
        border-color: $c-accent; }
      @include hover {
        border-color: $c-accent; } } }
  &--error {
    li {
      display: block;
      margin-bottom: 20px; }
    a {
      text-decoration: underline;
      color: $c-link;
      padding: 0;
      text-transform: none; } }
  &--news {
    li {
      display: block;
      position: relative;
      padding-left: 20px;
      @include notlast {
        margin-bottom: 10px; }
      @include lg {
        margin-bottom: 5px; }
      @include before {
        display: inline-block;
        @include icon-arrow(6, 6, 1, $c-accent, -135);
        position: absolute;
        top: .4em;
        left: 0; } }
    a {
      text-transform: none;
      color: $c-gray;
      display: inline;
      font-size: 15px;
      @include lg {
        font-size: 14px; } } } }

.aside-nav-toggle {
  display: none;
  margin-bottom: 15px;
  margin-top: -30px;
  @include md {
    display: block; } }

.aside-nav {
  padding-top: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 1, 1, 0.19);
  &.is-active {
 }    // display: block
  @include md {
    display: none; }
  &__title {
    padding-left: 10px;
    @include before {
      left: 10px; } } }

.cabinet-nav {
  background-color: #f5f5f5;
  position: sticky;
  top: 0;
  z-index: 12;
  @include sm {
    position: static; } }

.news-nav {
  border: 1px solid  $c-border;
  padding: 25px;
  @include lg {
    padding: 20px; }
  @include sm {
    padding: 15px; }
  &__title {
    margin-bottom: 10px; } }
