.parts-list {
  @include flex();
  @include sm {
    display: block; }
  &__img {
    @include cover;
    background-size: contain;
    background-position: 40px 20%;
    background-color: #fff;
    height: 270px;
    width: 270px;
    position: relative;
    padding: 30px 15px;
    @include flex(flex-end, normal, column);
    border: 1px solid  $c-border;
    position: relative;
    @include before {
      @include coverdiv;
      z-index: 0;
      background: rgba(255,255,255,0);
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 34%, rgba(255,255,255,1) 73%, rgba(255,255,255,1) 100%); }

    h5, .h5 {
      position: relative;
      z-index: 1; }
    @include md {
      width: 130px;
      height: 130px;
      padding: 10px; }
    @include sm {
      width: 100%;
      background-position: 110% 20%; } }
  &__list {
    width: calc(100% - 270px);
    border: 1px solid  $c-border;
    padding: 2% 4% 4%;
    @include flex();
    @include md {
      width: calc(100% - 130px); }
    @include sm {
      width: 100%; }
    @include xs {
      display: block; } }
  &__left {
    width: 50%;
    padding-right: 4%;
    @include sm {
      padding-right: 10px; }
    @include xs {
      width: 100%;
      padding: 0;
      margin-bottom: 12px; } }
  &__right {
    width: 50%;
    padding-left: 4%;
    @include sm {
      padding-left: 10px; }
    @include xs {
      width: 100%;
      padding: 0; } } }
