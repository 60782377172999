h1, h2, h3, h4, h5, h6 {
  font-weight: 400; }

.h1, h1 {
  text-transform: uppercase;
  color: #fff;
  @include bold;
  font-size: 48px;
  line-height: 1;
  @include css-lock(32, 48, 576, 1199, font-size); }

.h2, h2 {
  text-transform: uppercase;
  @include bold;
  color: $c-black;
  font-size: 21px;
  line-height: 1.2;
  @include css-lock(18, 21, 576, 1199, font-size);
  &--lg {
    @include css-lock(21, 24, 576, 1199, font-size); }
  &--white {
    color: #fff; }
  &--ttn {
    text-transform: none; }
  &--light {
    @include light;
    letter-spacing: -.01em; } }

.h2-subttl {
  &--sm {
    font-size: 12px;
    @include light;
    color: #a5a5a5; } }

.h3, h3 {
  font-size: 16px;
  @include bold;
  color: $c-black;
  text-transform: uppercase; }

.h4, h4 {}

.h5, h5 {
  text-transform: uppercase;
  @include bold;
  font-size: 18px;
  color: $c-text;
  @include tr(color .3s);
  @include xs {
    font-size: 16px; }
  a {
    text-decoration: underline; }
  &--white {
    color: #fff; }
  &--gray {
    color: #a5a5a5; }
  &--light {
    @include light; }
  &--accent {
    color: $c-accent; }
  &--ttn {
    text-transform: none; }
  &--sm {
    font-size: 16px; } }

.h6, h6 {
  @include bold;
  text-transform: uppercase;
  color: $c-black;
  font-size: 15px;
  @include xs {
    font-size: 14px; } }

.text {
  font-size: 15px;
  @include light;
  color: $c-text;
  text-align: justify;
  @include xs {
    font-size: 14px; }

  &--white {
    color: #fff; }
  &--ttu {
    text-transform: uppercase; }
  &--gray {
    color: $c-gray; }
  &--black {
    color: $c-black; }
  &--sm {
    font-size: 14px; }
  &--xs {
    font-size: 12px;
    line-height: 2;
    @include md {
      line-height: 1.4; } }
  &--xss {
    font-size: 12px; } }

.text-block {
  @include clr;
  img {
    float: left;
    margin-right: 30px;
    margin-bottom: 15px; }
  blockquote {
    color: $c-accent;
    @include italic-light;
    padding-top: 20px;
    padding-bottom: 20px;
    @include md {
      padding-top: 10px;
      padding-bottom: 10px; } }
  p {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 15px; } } }

.title-underlined {
  position: relative;
  padding-bottom: 14px;
  @include before {
    position: absolute;
    width: 53px;
    height: 1px;
    background-color: currentColor;
    bottom: 0;
    left: 0; }
  @include xs {
    padding-bottom: 8px; }

  &--center {
    @include before {
      left: 50%;
      transform: translate(-50%, 0); } }
  &--white {
    @include before {
      background-color: #fff; } }
  &--long {
    @include before {
      width: 170px;
      background-color: $c-accent; } }
  &--inherit {
    @include before {
      background-color: currentColor; } }
  &--sm {
    padding-bottom: 8px; } }
