.header {
  $this: &;
  position: relative;
  // z-index: 15
  @include lg {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 45px;
    &::before {
      content: '';
      z-index: 15;
      background-color: $c-black;
      width: 100%;
      height: 45px;
      top: 0;
      left: 0;
      position: absolute; } }
  &.is-active {
    .header__inner {
      transform: translate(0, 0); }
    .header__top-right-mob {
 } }      // z-index: 1
  &__inner {
    position: relative;
    transition: transform .3s;
    @include lg {
      position: absolute;
      z-index: 11;
      width: 100%;
      height: calc(100vh - 45px);
      top: 100%;
      left: 0;
      background-color: #fff;
      transform: translate(0, -100%);
      overflow: auto;
      background-color: #e6e6e6; } }
  &__burger {
    display: none;
    position: relative;
    z-index: 20;
    padding-right: 15px;
    @include lg {
      display: block; } }
  &__logo {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 15px;
    @include lg {
      left: 0; } }
  &__logo-wrap {
    @include lg {
      position: absolute;
      top: 0;
      left: 0; } }
  &__top {
    background-color: $c-black;
    position: relative;
    .container {
      @include lg {
        max-width: none; } }
    @include lg {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 160px;
      padding-right: 0;
      [class*="col-"] {
        position: static; } }
    @include md {
      padding-left: 130px; }
    @include xs {
      padding-left: 15px;
      padding-top: 40px; } }
  &__top-right {
    @include flex(space-between);
    @include lg {
      display: none; } }
  &__top-right-mob {
    display: none;
    background-color: $c-black;
    @include lg {
      display: flex;
      position: absolute;
      top: 0;
      right: 60px;
      z-index: 19; }
    @include xs {
      top: 45px;
      right: 0;
      z-index: 22; }
    .header-controls__item {
      &.lang {
        @include xs {
          position: absolute;
          z-index: 1;
          left: 0;
          margin-left: 0;
          bottom: 100%;
 } } } }          // display: none
  &__btn {
      flex-grow: 1;
      // +lg
      //   display: inline-block
      //   min-width: 185px
      @include xs {
        min-width: 0;
        margin-bottom: 0;
        .btn {
          font-size: 0;
          height: 100%;
          padding-left: 14px;
          padding-right: 14px;
          span {
            margin: 0;
            padding: 0;
            border: 0; }
          .icon {
            font-size: 16px; } } } }
  &__title {
    text-transform: uppercase;
    font-size: 14px;
    @include light;
    color: #fff;
    padding-top: 13px;
    padding-bottom: 13px; }

  &__bottom {
    background-color: #fff;
    .container {
      @include lg {
        max-width: none; } }

    @include lg {
      padding-left: 160px;
      padding-right: 0;
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: #e6e6e6; }
    @include md {
      padding-left: 130px; }
    @include xs {
      padding-left: 15px; } }
  &__bottom-in {
    @include flex(space-between, center);
    @include lg {
      display: block; } }

  &--gray {
    #{$this}__bottom {
      background-color: #f5f5f5; } } }


.logo {
  display: block;
  text-align: center;
  overflow: hidden;
  background-color: $c-accent;
  padding-top: 10px;
  color: #fff;
  @include lg {
    width: 148px; }
  @include md {
    width: 125px;
    // height: 85px
    padding-top: 5px;
    .icon {
      font-size: 35px; }
    span {
      font-size: 9px;
      padding-left: 3px;
      padding-right: 3px; } }
  span {
    font-size: 11px;
    text-transform: uppercase;
    @include thin;
    width: 100%;
    border-top: 1px solid  #15d153;
    padding: 5px;
    display: block; }
  .icon {
    fill: currentColor;
    stroke: currentColor;
    font-size: 65px;
    margin-bottom: 5px; } }

.burger {
  display: block;
  width: 28px;
  height: 28px;
  margin-top: 9px;
  margin-left: 15px;

  span:after, span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px; }

  span:after {
    top: 18px; }

  span {
    position: relative;
    display: block; }

  span, span:after, span:before {
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px; }
  &.is-active span {
    background-color: transparent; }

  &.is-active span:before {
    transform: rotate(45deg) translate(-1px, 0px); }

  &.is-active span:after {
    transform: rotate(-45deg) translate(6px, -7px); } }

.header-controls {
  &__list, ul {
    @include flex(); }
  &__item, li {
    display: block;
    margin-left: 1px;
    width: 45px; }
  &__link, a {
    color: #fff;
    @include flex(center, center);
    background-color: #35383e;
    height: 45px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    color: #fff;
    @include thin;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    @include hover {
      color: $c-accent; }
    &:hover {
      .tooltip {
        visibility: visible;
        opacity: 1; } } }
  .icon {
    fill: currentColor;
    font-size: 21px;
    @include tr(fill .3s); }
  .icon-heart {
    font-size: 25px; }
  &__item-search {} }


.tooltip {
  opacity: 0;
  visibility: hidden;
  @include tr(opacity .3s, visibility .3s);
  position: absolute;
  background-color: #fff;
  z-index: 15;
  display: block;
  width: 325px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid  $c-border;
  @include xs {
    transform: translate(0, 0);
    top: 90px;
    position: fixed;
    left: auto;
    right: 0;
    width: 310px; } }

.whish-list {
  &__item {
    @extend .header-controls__link;
    background-color: #fff;
    color: $c-text;
    justify-content: space-between;
    padding: 15px;
    padding-left: 0;
    padding-right: 40px;
    height: auto;
    position: relative;
    border-bottom: 1px solid  $c-border;
    @include xs {
      padding: 10px;
      padding-right: 30px; } }
  &__content {
    display: block;
    flex-grow: 1; }
  &__title {
    color: $c-text !important;
    text-decoration: underline;
    @include text-overflow;
    max-width: 200px;
    display: block !important;
    height: auto !important;
    padding: 0 !important;
    background-color: #fff !important;
    margin-bottom: 5px;
    @include tr(color .3s);
    @include hover {
      color: $c-accent !important; } }
  &__nmb, &__price {
    color: $c-text; }
  &__nmb {
    margin-right: 30px; }
  &__price {
    text-transform: uppercase;
    @include bold; }
  &__close {
    padding: 0;
    @include icon-close(12, 1, currentColor);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 15px;
    background-color: transparent;
    @include tr(color .3s);
    @include hover {
      color: $c-accent; } }
  &__btn {
    display: block;
    padding: 30px;
    @include xs {
      padding: 15px; }
    .btn {
      background-color: $c-accent;
      @include hover {
        color: #fff; } } } }

.header-btn {
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1; } } }

.cabinet-tooltip {
  text-align: center;
  padding: 30px;
  @include xs {
    padding: 15px; }
  &__title {
    font-size: 16px;
    text-transform: uppercase;
    color: $c-text;
    margin-bottom: 15px;
    display: block; }
  &__icon {
    display: block;
    background-color: $c-gray;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    border: 1px solid  $c-gray;
    color: #fff;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    margin-bottom: 15px;
    .icon {
      fill: currentColor;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 0;
      font-size: 45px;
      @include xs {
        font-size: 45px !important; } } }
  &__btn {
    display: block;
    margin-bottom: 20px;
    &:last-child {
      padding-top: 15px;
      margin-bottom: 0; }
    .btn {
      font-size: 15px !important;
      span {
        padding-right: 5px !important;
        margin-right: 5px !important;
        border-right: 1px solid currentColor !important; } } }
  &__conf {
    color: $c-text;
    text-transform: none;
    font-size: 16px;
    margin-bottom: 15px;
    a {
      text-decoration: underline;
      color: $c-link; } }
  &__links {
    @include flex(); }
  &__link {
    width: 33.33333%;
    display: block;
    @include tr(color .3s);
    text-decoration: underline;
    font-size: 16px;
    color: $c-text;
    text-transform: none;
    text-decoration: none;
    @include hover {
      text-decoration: underline; }
    .icon {
      fill: $c-gray;
      font-size: 30px;
      @include xs {
        font-size: 30px !important; } }
    @include hover {
      color: $c-accent;
      .icon {
        fill: $c-accent; } } }
  &__link-icon {
    display: block !important;
    text-align: center;
    // margin-bottom: 10px
    width: 100%;
    border-right: 0 !important;
    padding-right: 0 !important; } }

.lang {
  position: relative;
  @include lg {
    width: 90px !important;
    font-size: 0; }
  &:hover {
    .lang__link, a {
      &:nth-child(2) {
        visibility: visible;
        opacity: 1; } } }
  &__link, a {
    @include tr(visibility .3s, opacity .3s);
    min-width: 45px;
    @include lg {
      display: inline-flex !important;
      &:nth-child(2) {
        position: static;
        width: auto;
        visibility: visible;
        opacity: 1;
        margin-left: 1px;
        min-width: 44px; } }
    &:nth-child(2) {
      position: absolute;
      top: 100%;
      width: 100%;
      left: 0;
      z-index: 15;
      visibility: hidden;
      opacity: 0; } } }

.header-search {
  position: relative;
  form {
    height: 100%; }
  input {
    width: 100%;
    padding: 10px 30px;
    height: 100%;
    background-color: #fff;
    font-size: 14px;
    font-family: inherit;
    border-bottom: 1px solid  $c-border;
    @include placeholder {
      font-family: inherit;
      color: $c-gray; }
    @include xs {
      padding: 10px 15px; } }
  &__input {
    width: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    height: 100%;
    right: 100%;
    @include tr(width .3s);
    &.is-active {
      width: var(--width); } } }
