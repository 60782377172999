.about-item {
  margin-bottom: 65px;
  @include md {
    margin-bottom: 40px; }
  @include xs {
    margin-bottom: 20px; }
  &__img {
    @include cover;
    padding-top: 80%;
    margin-bottom: 20px;
    @include sm {
      padding-top: 40%; }
    @include xs {
      padding-top: 60%; } }
  &__title {
    margin-bottom: 10px; } }

.partners {}

.partner {
  background-color: #fafafa;
  padding: 20px;
  @include flex(center, center);
  height: calc(100% - 30px);
  margin-bottom: 30px;
  @include xs {
    margin-bottom: 15px;
    height: calc(100% - 15px); } }

.team {}

.member {
  text-align: center;
  margin-bottom: 30px;
  @include xs {
    margin-bottom: 15px; }
  &__img {
    padding-top: 100%;
    @include cover;
    margin-bottom: 15px; }
  &__title {
    @include bold; }
  &__subttl {
    font-size: 12px;
    color: $c-gray;
    @include light; } }
