.table {
  $this: &;
  display: table;
  border-collapse: collapse;
  background-color: #fff;
  width: 100%;
  @include md {
    &:not(table) {
      display: block; } }
  @include light;
  #{$this}__td,
  #{$this}__th {
    padding-top: 15px;
    padding-bottom: 15px;
    vertical-align: middle;
    padding-left: 15px;
    padding-right: 15px;
    display: table-cell;
    @include md {
      padding-left: 10px;
      padding-right: 10px;
      display: block; } }
  #{$this}__th {
    text-align: left;
    font-weight: 400;
    @include bold;
    font-size: 18px;
    background-color: $c-text-100;
    color: #fff;
    @include md {
      font-size: 15px; }
    padding-top: 11px;
    padding-bottom: 11px;
    &:nth-child(1) {
      width: 6%; }
    &:nth-child(2) {
      width: 8%; }
    &:nth-child(3) {
      width: 35%; }
    &:nth-child(4) {
      width: 8%; }
    &:nth-child(5) {
      width: 15%; }
    &:nth-child(6) {
      width: 7%; }
    &:nth-child(7) {
      width: 21%; } }
  #{$this}__td {
    font-size: 14px;
    color: $c-black;
    padding-top: 3px;
    padding-bottom: 3px; }
  #{$this}__tr {
    border: 1px solid  $c-border;
    display: table-row;
    position: relative;
    @include md {
      display: block;
      padding-left: 60px; }
    &:first-child {
      border-right: 1px solid  $c-border;
      border-left: 1px solid  $c-border;
      @include md {
        display: none; } }
    &.is-inactive {
      background-color: #f7f7f7;
      #{$this}__td {
        color: $c-gray; } } }
  #{$this}__tr--number {
    @include md {
      padding-left: 0; } }

  a {
    &:not([class*='btn']) {
      color: $c-accent;
      text-decoration: underline; } }
  strong {
    @include bold;
    text-transform: uppercase; }
  small {
    font-size: 12px;
    @include light;
    color: $c-gray; }
  th {
    text-align: left;
    font-weight: 400;
    @include bold;
    font-size: 18px;
    background-color: $c-text-100;
    color: #fff;
    @include md {
      font-size: 15px; } }
  tr {
    border: 1px solid  $c-border; }

  td, th {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 15px;
    vertical-align: middle;
    @include md {
      padding-left: 10px;
      padding-right: 10px; } }
  td {
    font-size: 14px;
    color: $c-black; }

  &--comp {
    @include sm {
      overflow-x: auto; }
    table {
      // +sm
 }      //   width: 690px
    th {
      &:nth-child(1) {
        width: 60%; }
      &:nth-child(2) {
        width: 10%; }
      &:nth-child(3) {
        width: 10%; }
      &:nth-child(4) {
        width: 30%; } } }

  &--search {
    &__img {
      border: 1px solid  $c-border;
      width: 42px;
      height: 42px;
      @include flex(center, center);
      position: relative;
      background-color: #fff;
      @include md {
        position: absolute;
        display: block;
        height: 100%;
        width: 60px;
        padding: 10px;
        border: 0;
        border-right: 1px solid  $c-border;
        top: 0;
        left: 0;
        img {
          position: relative;
          width: auto;
          height: auto; } }
      .icon {
        font-size: 21px;
        position: absolute;
        z-index: 0;
        path {
          fill: $c-border !important; } }
      img {
        @include coverdiv;
        object-fit: cover; } }
    &__price-inner {
      @include flex(space-between, center);
      width: 100%;
      strong {
        flex-grow: 1; }
      .btn-close {
        margin-right: 10px; } }
    &__price {
      @include md {
        border-top: 1px solid  $c-border;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 35px;
        display: flex !important;
        @include flex(space-between, center);
        .btn {
          height: 35px;
          width: 110px; } } }
    &__check,
    &__condition,
    &__ammount {
      @include md {
        display: inline-block !important; } } }

  &--cart {
    #{$this}__th {
      text-align: center;
      &:nth-child(1) {
        width: percentage(130/1170); }
      &:nth-child(2) {
        width: percentage(265/1170); }
      &:nth-child(3) {
        width: percentage(210/1170);
        text-align: left; }
      &:nth-child(4) {
        width: percentage(120/1170); }
      &:nth-child(5) {
        width: percentage(205/1170); }
      &:nth-child(6) {
        width: percentage(240/1170); } }
    #{$this}__td {
      &:nth-child(1) {}
      &:nth-child(2) {
        @include md {
          padding-right: 35px; } }
      &:nth-child(3) {}
      &:nth-child(4) {
        @include md {
          display: inline-block; } }
      &:nth-child(5) {
        text-align: center;
        @include md {
          display: inline-block; } }
      &:nth-child(6) {} }
    img {
      @include md {
        position: absolute;
        top: 10px;
        left: 0;
        max-width: 60px; } }
    .btn-delete {
      @include md {
        position: absolute;
        top: 10px;
        right: 10px; } }

    &__price {
      @include flex(space-between, center); } } }

