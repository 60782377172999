.orders {
  background-color: #fff;
  border: 1px solid  $c-border;
  padding: 25px 30px;
  &__items {
    @include md {
      border-right: 1px solid  $c-border;
      border-left: 1px solid  $c-border;
      border-top: 1px solid  $c-border; } }
  &__title {
    margin-bottom: 25px; }
  &__top {
    @include flex(flex-start, center);
    background-color: $c-text-100;
    color: #fff;
    padding: 12px 0;
    // padding-left: 0
    @include md {
      display: none; } }
  &__top-th {
    font-size: 18px;
    @include bold;
    &:nth-child(1) {
      width: percentage(205/1105);
      text-align: center; }
    &:nth-child(2) {
      width: percentage(170/1105); }
    &:nth-child(3) {
      width: percentage(160/1105);
      text-align: center; }
    &:nth-child(4) {
      width: percentage(245/1105); }
    &:nth-child(5) {
      width: percentage(275/1105); } } }

.orders-item-content {
  font-size: 14px;
  @include flex();
  @include sm {
    display: block; }
  &__left {
    width: 68%;
    @include sm {
      width: 100%; } }
  &__right {
    width: 32%;
    padding: 15px;
    @include flex(flex-start, normal, column);
    border-left: 1px solid  $c-border;
    margin-top: -45px;
    background-color: #fff;
    z-index: 1;
    @include md {
      margin-top: 0; }
    @include sm {
      width: 100%;
      border-left: 0;
      border-top: 1px solid  $c-border; } }
  &__row {
    @include flex(space-between, center);
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    @include notlast {
      border-bottom: 1px solid  $c-border; }
    @include sm {
      flex-wrap: wrap; } }
  &__row-bottom {
    padding-top: 14px;
    padding-bottom: 14px;
    color: $c-gray;
    strong {
      color: $c-text;
      padding-left: 10px; }
    > div:first-child {
      @include sm {
        padding-right: 10px; } } }
  &__img {
    width: 15%;
    text-align: center;
    @include sm {
      margin-bottom: 10px; } }
  &__ammount {
    width: 12%;
    text-align: center;
    @include sm {
      width: auto; } }
  &__price {
    @include bold;
    width: 12%;
    text-align: right;
    @include sm {
      width: auto; } }
  &__meta {
    // flex-grow: 1
    width: 61%;
    @include sm {
      width: 85%;
      margin-bottom: 10px; } }
  &__price-sm {
    @include bold; }
  &__title {
    color: $c-accent;
    text-decoration: underline;
    display: inline-block; }
  &__nmb {
    color: #acacac;
    font-size: 12px;
    display: inline-block; }
  &__right-title {
    @include medium;
    max-width: 150px;
    margin-bottom: 25px;
    @include lg {
      max-width: 125px; } }
  &__right-label {
    margin-bottom: 25px; }

  &__right-time {
    color: $c-gray;
    font-size: 12px;
    position: relative;
    padding-left: 35px;
    padding-bottom: 25px;
    border-bottom: 1px solid  $c-border;
    margin-bottom: 15px;
    span {
      display: block;
      max-width: 175px; }
    .icon {
      fill: currentColor;
      font-size: 28px;
      position: absolute;
      top: 0;
      left: 0; } }
  &__right-btn {
    font-size: 12px;
    color: $c-gray;
    span {
      display: inline-block;
      margin-bottom: 5px; } } }

.orders-item {
  @include md;

  &.is-active {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.26); }
  &__title {
    @include flex(flex-start, centet);
    @include tr(background-color .3s);
    @include notlast {
      border-bottom: 1px solid  $c-border; }
    @include hover {
      background-color: #f3f3f3;
      .more {
        color: $c-accent;
        @include before {
 } } }          // +rotate(-135)
    @include md {
      // display: block
      position: relative;
      padding-left: 60px;
      flex-wrap: wrap;
      justify-content: space-between; }
    @include xs {
      padding-left: 30px; }

    &.is-active {
      .more {
        @include before {
          @include rotate(-225); } } } }
  &__content-wrap {
    display: none;
    // &.is-active
 }    //   // display: flex
  &__td {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    .check {
      z-index: 0; }
    .more {
      z-index: 2; }
    @include md {
      padding-left: 10px;
      padding-right: 10px; }
    @include sm {
      padding-top: 5px;
      padding-bottom: 5px; }
    &:nth-child(1) {
      width: percentage(205/1105);
      text-align: center;
      @include md {
        width: 60px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-right: 1px solid  $c-border; }
      @include xs {
        width: 30px; } }

    &:nth-child(2) {
      width: percentage(170/1105);
      @include md {
        width: 100%; } }
    &:nth-child(3) {
      width: percentage(160/1105);
      text-align: center;
      @include md {
        width: 10%; }
      @include sm {
        width: auto; } }
    &:nth-child(4) {
      width: percentage(245/1105);
      @include md {
        width: 45%; }
      @include sm {
        width: auto; } }
    &:nth-child(5) {
      width: percentage(275/1105);
      @include flex(space-between, center);
      @include md {
        width: 45%;
        .check {
          position: relative;
          top: .2em;
          margin-right: 10px; } }
      @include sm {
        width: 100%; } } } }

.order-info {
  @extend .form-block;
  @include md {
    margin-top: 15px; }
  &__title {
    margin-bottom: 20px; }
  &__item {
    border-bottom: 1px solid  $c-border;
    padding: 9px 0;
    @include flex(space-between, center);
    margin-left: calc(0px - var(--form-block-padding));
    margin-right: calc(0px - var(--form-block-padding));
    padding-left: var(--form-block-padding);
    padding-right: var(--form-block-padding);
    &:last-child {
      border-bottom: 0;
      strong {
        color: #e64b45; } }
    &:first-child {
      border-top: 1px solid  $c-border; } }
  &__list {
    margin-bottom: 10px; }
  &__btn {
    margin-bottom: 15px; } }



