@include font('helveticaneuecyr-bold', 'helveticaneuecyr-bold-webfont');
@include font('helveticaneuecyr-roman', 'helveticaneuecyr-roman-webfont');
@include font('helveticaneuecyr-light', 'helveticaneuecyr-light-webfont');
@include font('helveticaneuecyr-medium', 'helveticaneuecyr-medium-webfont');
@include font('helveticaneuecyr-thin', 'helveticaneuecyr-thin-webfont');
@include font('helveticaneuecyr-lightitalic', 'helveticaneuecyr-lightitalic-webfont');

@mixin roman {
  font-family: 'helveticaneuecyr-roman', Arial, sans-serif; }
@mixin bold {
  font-family: 'helveticaneuecyr-bold', Arial, sans-serif; }
@mixin medium {
  font-family: 'helveticaneuecyr-medium', Arial, sans-serif; }
@mixin light {
  font-family: 'helveticaneuecyr-light', Arial, sans-serif; }
@mixin thin {
  font-family: 'helveticaneuecyr-thin', Arial, sans-serif; }
@mixin italic-light {
  font-family: 'helveticaneuecyr-lightitalic', Arial, sans-serif; }


@mixin xl {
  @include r(1440) {
    @content; } }
@mixin lg {
  @include r(1199) {
    @content; } }
@mixin md {
  @include r(991) {
    @content; } }
@mixin sm {
  @include r(767) {
    @content; } }
@mixin xs {
  @include r(575) {
    @content; } }
@mixin xss {
  @include r(479) {
    @content; } }

@mixin lgmin {
  @include r(1200) {
    @content; } }
@mixin mdmin {
  @include r(992) {
    @content; } }
@mixin smmin {
  @include r(768) {
    @content; } }
@mixin xsmin {
  @include r(576) {
    @content; } }


body {
  background: #FFF;
  line-height: 1.4;
  font-size: 15px;
  overflow-x: hidden;
  @include light;
  color: $c-text;
  @include no-scroll;
  @include xs {
    font-size: 14px; } }

.out {
 }  // overflow-x: hidden

img {
  max-width: 100%; }

strong {
  font-weight: 400;
  @include bold; }

.icon {
  display: inline-block; }



.btn {
  $this: &;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background-color: $c-accent;
  @include light;
  @include inline-flex(center, center);
  padding: 13px 11px;
  position: relative;
  @include tr(background-color .3s, border-color .3s, color .3s);
  @include hover {
    background-color: #282822; }
  span {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid  currentColor;
    display: inline-flex; }
  .icon {
    fill: currentColor;
    font-size: 16px; }

  &--full {
    width: 100%; }
  &--link {
    background-color: transparent;
    color: $c-accent;
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
    @include tr(padding .3s, background-color .3s, border-color .3s, color .3s);
    @include hover {
      background-color: $c-accent;
      color: #fff;
      padding-left: 10px;
      padding-right: 10px; } }
  &--sm {
    padding: 8px;
    .icon {
      font-size: 21px; } }
  &--md {
    padding-left: 18px;
    padding-right: 18px; }
  &--mds {
    padding-top: 11px;
    padding-bottom: 11px; }
  &--lg {
    padding-top: 17px;
    padding-bottom: 17px;
    @include sm {
      padding-top: 14px;
      padding-bottom: 14px; } }
  &--arrow {
    padding-right: 30px;
    &.is-active {
      @include before {
        transform: translate(0, -50%) rotate(-45deg); } }
    @include before {
      display: inline-block;
      pointer-events: none;
      @include icon-arrow(12, 12, 1, #fff, -135);
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%) rotate(-135deg);
      @include tr(transform .3s); } }
  &--combine {
    padding-right: 52px;
    padding-left: 15px;
    span {
      margin: 0;
      padding: 0;
      border: 0; }
    .icon {
      font-size: 16px; }
    .icon-heart {
      font-size: 20px; }
    #{$this}__title {
      @include inline-flex(space-between, center);
      width: 100%;
      @include tr(background-color .3s);
      .icon {
        margin-left: 15px; } }
    #{$this}__icon {
      @include inline-flex(center, center);
      background-color: #35383e;
      position: absolute;
      top: 0;
      right: 0;
      width: 42px;
      height: 100%;
      @include tr(background-color .3s); } }

  &--combine-double {
    @include hover {
      background-color: $c-accent; }
    #{$this}__title {
      @include hover {
        background-color: #282822; } }
    &#{$this}--sm {
      padding: 0;
      #{$this}__title {
        padding-right: 52px;
        padding-left: 15px;
        padding-top: 8px;
        padding-bottom: 8px;
        height: 100%; }
      #{$this}__icon {
        @include hover {
          background-color: $c-text-100; } } } }
  &--combine-sm {
    @extend .btn--sm;
    @include bold;
    @include hover {
      #{$this}__icon {
        background-color: #282822; } }
    #{$this}__title {
      justify-content: center; }
    #{$this}__icon {
      background-color: $c-accent;
      @include before {
        height: 60%;
        width: 1px;
        background-color: #70df95;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%); } } }
  &--square {
    padding: 0;
    width: 40px;
    height: 40px; }
  &--transp-gray {
    background-color: transparent;
    border: 1px solid #d1d1d1;
    color: $c-text-100;
    @include hover {
      background-color: $c-accent;
      border-color: $c-accent;
      color: #fff; } }
  &--transp-accent {
    background-color: transparent;
    border: 1px solid $c-accent;
    color: $c-accent;
    @include hover {
      background-color: $c-accent;
      border-color: $c-accent;
      color: #fff; } } }

.btn-close {
  display: inline-block;
  height: 19px;
  width: 20px;
  color: $c-text-100;
  border-radius: 50%;
  border: 1px solid currentColor;
  position: relative;
  @include icon-close(20, 1, currentColor);
  @include tr(color .3s);
  @include hover {
    color: $c-accent; }
  @include before {
    height: 60%;
    top: 50%;
    transform: translate(0, -50%) rotate(45deg); }
  @include after {
    height: 60%;
    top: 50%;
    transform: translate(0, -50%) rotate(-45deg); } }

.btn-delete {
  display: inline-block;
  height: 22px;
  width: 22px;
  color: #ff6f6f;
  border: 1px solid currentColor;
  position: relative;
  @include icon-close(20, 1, currentColor);
  @include tr(color .3s);
  @include before {
    height: 60%;
    top: 50%;
    transform: translate(0, -50%) rotate(45deg); }
  @include after {
    height: 60%;
    top: 50%;
    transform: translate(0, -50%) rotate(-45deg); } }

.btn-arrow {
  $this: &;
  color: #fff;
  @include light;
  font-size: 15px;
  @include inline-flex(flex-start, center);
  padding: 15px 20px;
  padding-right: 10px;
  background-color: #2c2c2c;
  @include tr(color .3s);
  @include hover {
    #{$this}__arrow {
      width: 40px; } }
  &__title {
    display: block;
    margin-right: 10px; }
  &__arrow {
    position: relative;
    width: 25px;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    display: block;
    transition: transform .3s, width .3s;
    &::before {
      width: 100%;
      content: '';
      position: absolute;
      height: 1px;
      background-color: currentColor;
      top: 50%;
      right: -1px;
      transform: translate(0, -50%);
      transition: width .3s; }
    &::after {
      display: inline-block;
      position: absolute;
      @include icon-arrow(8, 8, 1, currentColor, 225);
      content: '';
      top: 50%;
      transform: translate(0, -50%) rotate(225deg) !important;
      right: 0; } }

  &--link {
    padding: 0;
    background-color: transparent;
    color: $c-text-100; } }

.counter {
  input {
    height: 30px;
    width: 30px;
    border: 1px solid  $c-gray;
    font-family: inherit;
    font-size: inherit;
    padding: 5px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle; }
  button {
    background-color: transparent;
    color: $c-gray;
    @include light;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 30px;
    height: 30px;
    @include before {
      width: 12px;
      border-bottom: 1px solid  $c-gray;
      @include vcenter; }
    @include after {
      height: 12px;
      @include vcenter;
      border-right: 1px solid  $c-gray; }
    &:first-child {
      @include after {
        display: none; } } } }

.price {
  @include medium;
  font-size: 30px;
  color: $c-accent;
  @include sm {
    font-size: 28px; }
  @include xs {
    font-size: 26px; }

  &--sm {
    font-size: 21px;
    @include sm {
      font-size: 18px; }
    @include xs {
      font-size: 16px; } } }

.check {
  $this: &;
  color: $c-accent;
  @include light;
  font-size: 14px;
  @include inline-flex(flex-start, center);
  &__title {
    display: block;
    // vertical-align: middle
    margin-left: 5px; }
  &__icon {
    display: block;
    // vertical-align: middle
    border: 1px solid  currentColor;
    height: 15px;
    width: 15px;
    position: relative;
    @include before {
      @include icon-arrow(8, 5, 1, currentColor, -45);
      display: inline-block;
      position: absolute;
      top: 2px;
      left: 2px; } }

  &.is-intransport {
    color: $c-text;
    #{$this}__icon {
      border: 0;
      @include before {
        display: none; }
      .icon {
        font-size: 16px; } } }
  &.is-rejected {
    color: #ad1010;
    #{$this}__icon {
      position: relative;
      @include before {
        border: 0;
        width: 7px;
        border-bottom: 1px solid currentColor;
        @include rotate(0);
        left: 3px; } } } }

.highlighted {
  display: inline-block;
  background-color: #d7fde4;
  padding-left: 2px;
  padding-right: 2px; }

.hr, hr {
  height: 1px;
  width: 100%;
  background-color: $c-border;
  color: $c-border;
  border: none;
  margin-top: 25px;
  margin-bottom: 25px; }

.list-link {
  display: block;
  font-size: 18px;
  @include light;
  color: #383838;
  border-bottom: 1px solid  $c-border;
  position: relative;
  padding-bottom: 5px;
  padding-right: 10px;
  @include tr(color .3s);
  // +text-overflow
  @include md {
    font-size: 14px;
    margin-bottom: 3px;
    padding-bottom: 3px; }
  @include before {
    display: inline-block;
    @include icon-arrow(10, 10, 1, currentColor, -135);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%) rotate(-135deg);
    right: 1px;
    @include md {
      @include icon-arrow(7, 7, 1, currentColor, -135);
      transform: translate(0, -50%) rotate(-135deg); } }
  @include hover {
    text-decoration: underline;
    color: $c-accent; }
  @include notlast {
    margin-bottom: 15px; } }

.user {
  @include flex(flex-start, center);

  @include xs {
    display: block;
    font-size: 0; }
  &__title {
    @include xs {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 45px); } }
  &__img {
    @include cover;
    background-color: #fff;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    margin-right: 15px;
    @include sm {
      width: 45px;
      height: 45px;
      .icon {
        font-size: 35px; } }
    @include xs {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: top;
      .icon {
        font-size: 20px; } }
    .icon {
      position: absolute;
      z-index: 0;
      fill: #d0d0d0;
      font-size: 45px;
      bottom: 5px;
      left: 50%;
      transform: translate(-50%, 0); } } }

.orders-thumbs {
  @include flex(flex-end);
  @include xs {
    justify-content: flex-start; } }

.orders-thumb {
  color: $c-gray;
  text-align: center;
  &__nmb {
    @include bold;
    font-size: 24px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    padding-right: 14px;
    padding-left: 14px;
    .icon {
      font-size: 12px;
      fill: $c-accent;
      position: absolute;
      top: 0;
      right: 0; } }
  &__title {
    @include light;
    font-size: 12px;
    @include xs {
      display: none; } } }

.cabinet {
  &__block {
    &:not(.cabinet__block--last) {
      margin-bottom: 60px;
      @include sm {
        margin-bottom: 40px; }
      @include xs {
        margin-bottom: 30px; } } } }

.more {
  position: relative;
  display: inline-block;
  padding-left: 15px;
  font-size: 15px;
  @include light;
  color: #8388c7;
  text-decoration: underline;
  @include tr(color .3s);
  @include before {
    display: inline-block;
    @include icon-arrow(6, 6, 1, currentColor, -45);
    position: absolute;
    top: .4em;
    left: 0;
    @include tr(transform .3s); } }

.status {
  border: 1px solid  $c-accent;
  color: $c-accent;
  display: inline-block;
  padding: 7px 10px;
  background-color: #e5f5ec;
  text-transform: uppercase;
  font-size: 14px;
  @include medium;
  @include rotate(-10);
  &.is-rejected {
    color: #ad1010;
    border-color: #ad1010;
    background-color: rgba(#ad1010, .3); }
  &.is-intransport {
    color: $c-text;
    border-color: $c-text;
    background-color: rgba($c-text, .3); } }


.subscribe {
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  input {
    @include xs {
      margin-bottom: 10px; } }
  &__title {
    @include md {
      margin-bottom: 15px; }
    @include xs {
      margin-bottom: 10px; } } }

.map {
  padding-top: 60%;
  position: relative;
  iframe {
    @include coverdiv; } }

.application-help {
  color: $c-gray;
  font-size: 12px;
  @include light;
  text-align: center;
  max-width: 275px;
  margin-left: auto;
  margin-right: auto;
  .icon {
    fill: currentColor; } }

.auth-block {
  text-align: center;
  &__title {
    margin-bottom: 10px; }
  &__icon {
    margin-bottom: 20px;
    .icon {
      fill: $c-accent;
      font-size: 60px; } }

  &__icon-lg {
    .icon {
      font-size: 220px; } }
  &__text {
    @include bold;
    color: $c-gray;
    font-size: 18px;
    margin-bottom: 65px;
    strong {
      color: $c-text-100;
      a {
        color: inherit; } }
    a {
      color: $c-link; } }
  &__text-mb-sm {
    margin-bottom: 35px; }
  &__links {} }

.auth-link {
  color: $c-link;
  text-decoration: underline;
  @include tr(color .3s);
  @include hover {
    color: #acacac; } }

.viewed-items {
  --viewed-items-gap: 15px;
  @include flex(flex-start, normal, row, wrap);
  margin-left: calc(0px - var(--viewed-items-gap));
  margin-right: calc(0px - var(--viewed-items-gap));
  &__item {
    width: calc(#{percentage(3/12)} - (var(--viewed-items-gap) * 2));
    margin-left: var(--viewed-items-gap);
    margin-right: var(--viewed-items-gap);
    margin-bottom: var(--viewed-items-gap);
    @include md {
      width: calc(#{percentage(4/12)} - (var(--viewed-items-gap) * 2)); }
    @include sm {
      width: calc(#{percentage(6/12)} - (var(--viewed-items-gap) * 2)); }
    @include xs {
      width: calc(#{percentage(12/12)} - (var(--viewed-items-gap) * 2)); } } }


.viewed-item {
  @include flex(flex-start, normal, column);
  border: 1px solid  $c-border;
  @include tr(box-shadow .3s);
  @include hover {
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.18); }
  &__img {
    display: block;
    margin-bottom: 20px; }
  &__title {
    display: block;
    margin-bottom: 15px;
    color: $c-gray;
    font-size: 18px;
    text-decoration: underline;
    @include lg {
      margin-bottom: 10px; } }
  &__content {
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 20px;
    display: block;
    @include lg {
      padding-left: 20px;
      padding-right: 20px; } }
  &__price {
    display: block; }
  &__rating {
    display: block;
    margin-bottom: 10px;
    @include lg {
      margin-bottom: 5px; } } }

.rating {
  color: $c-gray;
  font-size: 12px;
  display: inline-block;
  .icon {
    fill: currentColor; } }

.top-search-line {
  @include flex(space-between);
  @include sm {
    display: block; }
  .select {
    width: 210px;
    margin-left: 15px;
    @include sm {
      margin-left: 0;
      margin-top: 15px; } } }

.color-thumb {
  width: 11px;
  height: 11px;
  display: inline-block; }

.spinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }

