.search {
  $this: &;
  background-color: transparent;
  text-align: left;
  position: relative;
  margin-top: 77px;
  width: 100%;
  &__close {
    display: block;
    @include icon-close(30, 1, #fff);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 10px;
    display: none; }
  &__wrap {
    width: 100%;
    position: relative;
    @include xs {
      display: none;
      .is-home & {
        display: block; }
      .common-home & {
        display: block; } }
    &.is-fixed {
      position: fixed;
      z-index: 15;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba($c-text, .5);
      padding-top: 10px;
      padding-bottom: 10px;
      .search {
        @extend .container;
        margin-top: 0;
        &__tabs {
          padding-left: 15px;
          padding-right: 15px; }
        &__close {
          display: block; }
        &__tabs {
          display: none; }
        &__block {
          &:last-child {
            display: none !important; }
          &:first-child {
            display: block !important; } }
        &__content {
          padding-top: 5px;
          padding-bottom: 5px;
          border-radius: 0;
          box-shadow: none;
          @include before {
            box-shadow: none; } } }
      .custom-select {
        &__opener {
          padding-top: 5px;
          padding-bottom: 5px; } }
      .btn {
        padding-top: 8px;
        padding-bottom: 8px; } } }
  &__tabs {
    font-size: 0;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: calc(100% - 15px);
    z-index: 0; }
  &__tab {
    display: inline-block;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    @include bold;
    padding: 20px 30px 30px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: $c-text-100;
    border: 1px solid  #656565;
    margin-right: 5px;
    @include sm {
      padding: 10px;
      padding-bottom: 25px;
      font-size: 15px;
      width: 50%;
      margin-right: 0 !important;
      @include text-overflow; }
    @include xs {
      width: 100%;
      border-radius: 0;
      padding-bottom: 10px;
      &:last-child {
        padding-bottom: 25px; } }
    &.is-active {
      background-color: $c-accent;
      margin-right: 8px;
      border-color: $c-accent; } }
  &__content {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 1, 0.11);
    padding: 40px 15px;
    position: relative;
    z-index: 1;
    @include sm {
      padding: 30px 10px 20px; } }
  &__block {
    display: none;
    &.is-active {
      display: block; } }

  &--sm {
    margin-top: 0;
    #{$this}__content {
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: 0px -3px 4px 0px rgba(0, 0, 2, 0.2);
      position: relative;
      @include before {
        position: absolute;
        bottom: -1px;
        left: 25px;
        width: calc(100% - 50px);
        height: 1px;
        box-shadow: 0px -3px 4px 0px rgba(0, 0, 2, .5); } } } }

.search-toggle {
  display: none;
  // margin-bottom: 20px
  margin-top: 20px;
  @include tr(margin-bottom .3s);
  &.is-active {
    margin-bottom: 20px; }
  @include xs {
    display: inline-flex; } }
