.item-gallery {
  &__title {
    margin-bottom: 15px;
    @include sm {
      margin-bottom: 0px; } }
  &__meta-item {
    @include notlast {
      margin-right: 40px;
      @include xs {
        margin-right: 10px; } } }

  &__price {
    @include flex(space-between);
    margin-bottom: 15px;
    @include xs {
      display: block; } }
  &__meta {
    @include flex(flex-start, center);
    .check {
      margin-right: 40px;
      @include xs {
        margin-right: 10px; } } }
  &__block {
    padding-bottom: 20px;
    padding-top: 15px;
    @include notlast {
      border-bottom: 1px solid  $c-border; }
    @include sm {
      padding-bottom: 10px;
      padding-top: 10px; } }
  &__preview {
    height: 100%;
    border-left: 1px solid  $c-border;
    border-right: 1px solid  $c-border;
    @include md {
      border: 0;
      border-bottom: 1px solid  $c-border;
 } } }      // padding-top: 50%

.item-preview {
  position: relative;
  &__img {
    visibility: hidden;
    opacity: 0;
    @include cover;
    background-color: #fff;
    background-size: contain;
    @include coverdiv;
    @include tr(opacity .3s, visibility .3s);
    &.is-active {
      visibility: visible;
      opacity: 1; } }
  &__thumbs {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    left: 10px;
    display: none; }
  &__thumb {
    @include inline-flex(center, center);
    border: 1px solid  $c-border;
    width: 48px;
    height: 48px;
    &.is-active {
      border-color: $c-accent; } } }

.fotorama {
  &__wrap {
    height: 100%;
    @include flex(space-between, normal, column); }
  &__nav {
    text-align: left;
    padding-left: 10px; }
  &__thumb-border {
    // height: 47px !important
    // width: 47px !important
    border-color: $c-accent;
    // margin: 0 !important
    background: transparent !important; }
  &__thumb {
    // height: 47px
    // width: 47px
    background-color: #fff;
    position: relative;
    @include before {
      @include coverdiv;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border: 1px solid  $c-border; }
    @include flex(center, center);
    img {
      max-width: 100%;
      // width: 49px !important
      // height: 26px !important
      position: static; } }
  &__nav__frame {
    // width: 47px !important
 } }    // height: 47px !important
