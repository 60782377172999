.news-item {
  --news-item-padding: 25px;
  --news-item-margin: 15px;
  $this: &;
  @include flex();
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  @include tr(box-shadow .3s);
  @include lg {
    --news-item-padding: 20px;
    --news-item-margin: 10px; }
  @include sm {
    --news-item-padding: 15px;
    --news-item-margin: 8px; }
  @include xs {
    display: block; }
  @include hover {
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.18);
    #{$this}__title {
      .h5, h5 {
        color: $c-accent;
        text-decoration: underline; } }
    #{$this}__more {
      .btn-arrow {
        color: $c-accent; } } }
  &__img {
    display: block;
    background-color: $c-gray;
    @include flex();
    @include cover;
    position: relative;
    width: percentage(300/870);
    padding-top: 50%;
    @include xs {
      width: 100%; } }
  &__title {
    margin-bottom: var(--news-item-margin);
    display: block;
    @include lg {
      font-size: 16px; }
    @include sm {
      font-size: 15px; }
    .h5, h5 {
      @include lg {
        font-size: 16px; }
      @include sm {
        font-size: 15px; } } }
  &__text {
    margin-bottom: var(--news-item-margin);
    display: block;
    max-height: 75px;
    @include sm {
      font-size: 14px; } }
  &__content {
    @include flex(flex-start, normal, column);
    width: percentage(570/870);
    padding: var(--news-item-padding);
    display: block;
    @include xs {
      width: 100%; } }
  &__icons {
    @include flex(flex-start, center);
    margin-bottom: calc(var(--news-item-margin) / 1.5); }
  &__date-label {
    position: absolute;
    font-size: 10px;
    color: #fff;
    background-color: $c-accent;
    padding: 5px 14px;
    top: 5px;
    left: 5px;
    text-align: center;
    strong {
      font-size: 17px;
      line-height: .8;
      display: block; } }
  &__meta {
    display: block;
    color: $c-gray;
    @include notlast {
      margin-right: 20px; }
    @include lg {
      font-size: 14px;
      @include notlast {
        margin-right: 10px; } }
    @include sm {
      font-size: 12px; }
    .icon {
      fill: currentColor;
      margin-right: 5px; } }
  &__more {
    display: block; }

  &--sm {
    flex-direction: column;
    #{$this}__img {
      width: 100%;
      padding-top: 70%;
 }      // margin-bottom: calc(var(--news-item-margin) * 1.5)
    #{$this}__content {
      width: 100%; } } }
