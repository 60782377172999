.features {
  background-color: $c-black;
  padding-top: 45px;
  padding-bottom: 45px; }

.feature {
  @include flex(flex-start, top);
  @include md {
    margin-bottom: 15px; }
  .icon {
    fill: $c-accent;
    font-size: 40px; }
  &__icon {
    width: 35px;
    margin-right: 15px; }
  &__content {
    flex-grow: 1; }
  &__title {
    text-transform: uppercase;
    color: #fff;
    @include medium;
    font-size: 15px; }
  &__text {
    font-size: 15px;
    @include light;
    color: #c1c1c1; } }

