.breadcrumbs {
  margin-bottom: 25px;
  padding-top: 35px;
  li {
    display: inline-block;
    &.is-active {
      color: $c-black;
      pointer-events: none;
      text-decoration: none; } }
  a {
    font-size: 14px;
    @include light;
    color: $c-accent;
    text-decoration: underline;
    &.is-active {
      color: $c-black;
      pointer-events: none;
      text-decoration: none; } }

  &--sm {
    padding-top: 20px;
    @include xs {
      padding-top: 10px; } }

  &--hero {
    padding-top: 20px;
    li {
      color: #cdcdcd; }
    li, a {
      &.is-active {
        color: #cdcdcd; } } } }
