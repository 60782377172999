.popup {
  $this: &;
  position: fixed;
  z-index: 30;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  padding: 15px;
  background-color: rgba(#000, .85);
  @include vertical;
  visibility: hidden;
  opacity: 0;
  @include tr(opacity .3s, visibility .3s);
  &__inner {
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    width: 100%;
    max-width: 900px;
    background-color: #fff;
    padding: 50px 20px;
    position: relative;
    @include sm {
      padding-left: 15px;
      padding-right: 15px; }
    @include xs {
      font-size: 14px; } }
  &__close {
    @include icon-close(20, 1, currentColor);
    background-color: transparent;
    position: absolute;
    top: 15px;
    right: 15px;
    @include tr(color .3s);
    @include hover {
      color: $c-accent; } }
  &__form {
    text-align: left; }
  &__title {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 15px; } }

  &--active {
    visibility: visible;
    opacity: 1;
    #{$this}__spinner {
      display: block; } }
  &--sm {
    #{$this}__inner {
      max-width: 570px;
      padding: 50px;
      @include md {
        padding-left: 25px;
        padding-right: 25px; }
      @include sm {
        padding-left: 15px;
        padding-right: 15px; } } } }
