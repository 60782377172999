.hero {
  $this: &;
  background-color: $c-black;
  @include cover;
  position: relative;
  z-index: 10;
  text-align: center;
  padding-top: 19px;
  @include lg {
    padding-top: 67px; }
  @include md {
    padding-top: 29px; }
  @include xs {
    padding-top: 45px; }
  .container {
    position: relative;
    z-index: 1; }
  &__bg {
    @include cover;
    @include coverdiv;
    z-index: 0;
    opacity: .5; }
  &__title {
    .text {
      text-align: center; }
    .h1, h1 {
      margin-bottom: 5px; } }

  &--lg {
    // +flex(center, center, column)
    height: calc(100vh - 93px);
    padding-bottom: 100px;
    padding-top: 10%;
    @include lg {
      height: calc(100vh - 45px); }
    @include xs {
      height: auto;
      padding-top: 80px;
      padding-bottom: 40px;
      #{$this}__block {
        margin-bottom: 30px; } }
    .container, .row {
      height: 100%; }
    .col-12 {
      &:last-child {
        @include flex(flex-end, center, column); } }
    @include before {
      bottom: 0;
      left: 0;
      width: 100%;
      position: absolute;
      height: 115px;
      background-image: linear-gradient(to top, #fff, #fff 50%, rgba(#fff,0)); } }
  &--no-search {
    padding-top: 45px;
    padding-bottom: 30px;
    text-align: left; }
  &--plain {
    background-color: #e6e6e6;
    padding-top: 15px;
    padding-bottom: 20px;
    text-align: left;
    @include lg {
 }      // padding-top: 40px
    @include xs {
      padding-top: 50px; }
    #{$this}__btn {
      text-align: right;
      @include xs {
        .btn {
          font-size: 0;
          padding: 8px;
          span {
            margin: 0;
            padding: 0;
            border: 0; } } } }
    #{$this}__user {
      @include xs {
        margin-bottom: 10px; } } }
  &--empty {
    height: 115px; }
  &--sm {
    text-align: left;
    padding-top: 50px;
    padding-bottom: 15px;
    min-height: 115px; }
  &--md {
    min-height: 120px; }
  &--under {
    padding-bottom: 125px;
    padding-top: 45px;
    text-align: left;
    z-index: 1; } }

