.info-list {
  dl {
    @include clr;
    margin-bottom: -10px; }
  dt, dd {
    float: left;
    margin-bottom: 10px; }
  dt {
    width: 20%;
    @include xs {
      width: 30%; } }
  dd {
    width: 80%;
    @include xs {
      width: 70%; } }

  &--simple {

    dd, dt {
      width: auto;
      float: none;
      @include xs {
        width: auto; } }
    dd {
      @include bold; }
    dt {
      float: left;
      margin-right: 5px; } } }
