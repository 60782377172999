.footer {
  padding-top: 80px;
  padding-bottom: 90px;
  @include md {
    padding-top: 40px;
    padding-bottom: 40px; }
  @include xs {
    padding-top: 20px;
    padding-bottom: 20px; }
  &__logo {
    margin-bottom: 10px;
    .icon {
      font-size: 69px; } }
  &__copy {
    font-size: 12px;
    color: #727171;
    margin-bottom: 25px;
    @include sm {
      margin-bottom: 15px; } }
  &__text-sm {
    color: #727171;
    font-size: 11px;
    @include sm {
      margin-bottom: 15px; } }
  &__title {
    margin-bottom: 25px;
    padding-bottom: 20px;
    @include md {
      margin-bottom: 10px;
      padding-bottom: 10px; } } }

.footer-contacts {
  @include xs {
    margin-bottom: 15px; }
  &__phone,
  &__text {
    font-size: 15px;
    color: #727171;
    @include notlast {
      margin-bottom: 20px;
      @include lg {
        margin-bottom: 15px; }
      @include xs {
        margin-bottom: 10px; } } }
  &__link {
    color: $c-accent;
    text-decoration: underline; } }
