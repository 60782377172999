.social {
  color: #fff;
  li {
    display: inline-block; }
  a {
    @include flex(center, center);
    background-color: $c-accent;
    width: 35px;
    height: 35px;
    padding: 5px; }
  .icon {
    fill: currentColor;
    font-size: 18px; } }
