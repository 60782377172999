.is-center {
  text-align: center; }

.has-bd-top {
  border-top: 1px solid  $c-border; }

.has-bd-bottom {
  border-bottom: 1px solid  $c-border; }

.has-no-pt {
  padding-top: 0; }

.is-h-100 {
  height: 100%; }
