.error-page-block {
  &__title {
    text-transform: uppercase;
    color: $c-text-100;
    @include bold;
    @include css-lock(80, 115, 576, 1199, font-size); }
  &__subttl {
    margin-bottom: 35px;
    @include md {
      margin-bottom: 25px; }
    @include sm {
      margin-bottom: 10px; } }
  &__nav-title {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 15px; } } }

// .error-img
//   +flex()
//   &__nmb
//     color: #fff
//     font-size: 224px
//     background-color: $c-link
//     border-radius: 50%
//     width: 215px
//     height: 215px
//     +flex(center, center)
//     +roman
//     position: relative
//     z-index: 0
//     &:nth-child(1)
//       left: 10px
//     &:nth-child(3)
//       right: 10px
//   &__img
//     position: relative
//     background-color: #fff
//     z-index: 1
//     height: 400px
//     +rotate(30)
//     +flex(center, center)
//     transform: translate(0, -50%) rotate(30deg)
//     img
//       +rotate(-30)

//     +before
//       height: 400px
//       width: 1px
//       // background-color: #000
//       box-shadow: 3px 0 5px rgba(#000, .2)
//       position: absolute
//       left: 37px
//       top: 50%
//       transform: translate(0, -50%)
//     +after
//       height: 400px
//       width: 1px
//       box-shadow: 3px 0 5px rgba(#000, .2)
//       position: absolute
//       right: 9px
//       top: 50%
//       transform: translate(0, -50%)
