.aside {
  $this: &;
  &--news {
    #{$this}__title {
      margin-bottom: 20px;
      margin-left: 25px;
      @include lg {
        margin-left: 20px; } }
    #{$this}__block {
      margin-bottom: 25px;
      @include md {
        &:not(.aside__block-nav) {
          display: none; } } } }
  &--sticky {
    position: sticky;
    top: 0;
    @include md {
      position: static; } } }

.aside-filters {
  font-size: 0;
  margin-left: -1px;
  margin-right: -1px;
  li {
    display: inline-block;
    width: 33.33333%;
    vertical-align: top;
    padding-left: 1px;
    padding-right: 1px;
    font-size: 15px;
    margin-bottom: 1px; }
  a {
    display: block;
    background-color: $c-text-100;
    color: #fff;
    text-align: center;
    padding: 5px; } }

.aside-search {
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 1, 1, 0.19);
  margin-bottom: 30px;
  &__inner {
    padding: 30px;
    @include lg {
      padding: 20px; }
    @include md {
      padding: 15px; } }

  &__title, &__field {
    @include notlast {
      margin-bottom: 20px; } } }
