.contacts {
  $this: &;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 1, 1, 0.19);
  padding: 35px 25px 0;
  text-align: center;
  @include md {
    padding: 20px;
    padding-bottom: 0; }
  &__title {
    margin-bottom: 25px;
    // text-align: center
    .h2-subttl--sm {
      max-width: 360px;
      margin-left: auto;
      margin-right: auto; } }
  &__title-comb {
    @include flex(flex-start, center);
    .icon {
      fill: currentColor;
      margin-right: 5px;
      font-size: 1.3em; } }
  &__contacts {
    margin-bottom: 30px;
    margin-right: -15px;
    margin-right: -15px;
    @include flex(flex-start, normal, row, wrap);
    @include sm {
      margin-left: -10px;
      margin-right: -10px;
      margin-bottom: 10px; }
    @include xs {
      display: block; } }
  &__contact {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 25px;
    @include sm {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 10px;
      @include notlast {
        margin-bottom: 10px; } } }
  &__social {
    background-color: #fbfbfb;
    padding: 20px 25px;
    margin-left: -25px;
    margin-right: -25px;
    text-align: center;
    @include md {
      padding-left: 20px;
      padding-right: 20px;
      margin-left: -20px;
      margin-right: -20px; }
    .text {
      text-align: center;
      margin-bottom: 15px; } }

  &--sm {
    --contacts-padding: 50px;
    padding-left: var(--contacts-padding);
    padding-right: var(--contacts-padding);
    padding-bottom: calc(var(--contacts-padding) / 2);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
    text-align: left;
    margin-bottom: 0;
    @include flex(space-between, normal, column);
    @include lg {
      --contacts-padding: 40px; }
    @include md {
      --contacts-padding: 20px;
      padding-bottom: var(--contacts-padding); }
    @include sm {
      margin-bottom: 25px;
      height: auto; }
    #{$this}__block {
      border-bottom: 1px solid  $c-border;
      margin-left: calc(0px - var(--contacts-padding));
      margin-right: calc(0px - var(--contacts-padding));
      padding-left: var(--contacts-padding);
      padding-right: var(--contacts-padding); }
    #{$this}__social {
      background-color: #fff;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0; }
    #{$this}__map {
      padding-top: 20px;
      flex-grow: 1;
      // padding-bottom: 20px
      margin-bottom: 40px;
      .map {
        height: 100%; } } }
  &--full {
    --contacts-padding: 100px;
    padding-left: var(--contacts-padding);
    padding-right: var(--contacts-padding);
    @include md {
      --contacts-padding: 20px; }
    #{$this}__contacts {
      justify-content: space-between; }
    #{$this}__contact {
      max-width: 370px;
      @include sm {
        max-width: none; } }
    #{$this}__social {
      padding-left: var(--contacts-padding);
      padding-right: var(--contacts-padding);
      margin-left: calc(0px - var(--contacts-padding));
      margin-right: calc(0px - var(--contacts-padding));
      @include md {
        padding-left: var(--contacts-padding);
        padding-right: var(--contacts-padding);
        margin-left: calc(0px - var(--contacts-padding));
        margin-right: calc(0px - var(--contacts-padding)); } } } }

.contact {
  text-align: left;
  // color: $c-gray
  &__title {
    position: relative;
    padding-bottom: 10px;
    padding-right: 30px;
    border-bottom: 1px solid  $c-border;
    margin-bottom: 8px;
    .icon {
      fill: $c-text;
      position: absolute;
      top: .1em;
      right: 0;
      font-size: 18px; } }
  &__meta {
    a {
      color: $c-accent;
      text-decoration: underline; }
    p {
      @include notlast {
        margin-bottom: 15px;
        @include md {
          margin-bottom: 10px; } } } } }

.contact-manager {
  @include cover;
  position: relative;
  margin-top: 75px;
  text-align: center;
  padding-top: 75px;
  padding-bottom: 65px;
  .text {
    text-align: center;
    color: #bababa; }
  .container {
    position: relative; }
  &__img {
    position: absolute;
    bottom: -65px;
    left: 0;
    font-size: 0;
    z-index: 0; }
  &__title {
    margin-bottom: 15px; }
  &__block {
    position: relative;
    z-index: 1; } }

