.delivery-block {
  padding-top: 50px;
  @include md {
    padding-top: 30px; } }

.delivery-item {
  border: 1px solid  $c-border;
  text-align: center;
  height: 100%;
  padding: 0 40px 20px;
  @include md {
    padding-left: 10px;
    padding-right: 10px; }
  @include sm {
    margin-bottom: 10px;
    height: auto; }
  &__img {
    height: 140px;
    @include flex(flex-end, center, column);
    margin-bottom: 15px;
    @include md {
      height: 100px;
      margin-bottom: 5px;
      img {
        // width: 100px
        height: 86px; } } }
  &__title {
    @include light;
    color: $c-accent;
    font-size: 14px;
    margin-bottom: 10px;
    @include md {
      margin-bottom: 5px; } }
  &__text {
    text-align: center; } }
